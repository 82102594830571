import React, { useState } from 'react';

const FormspreeContact = () => {
  const [formData, setFormData] = useState({
    email: '',
    message: '',
    name: ''
  });
  const [formStatus, setFormStatus] = useState({
    isSubmitting: false,
    isSubmitted: false,
    error: null
  });
  const [validation, setValidation] = useState({
    email: true,
    message: true,
    name: true
  });

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newValidation = {
      email: emailRegex.test(formData.email),
      message: formData.message.length >= 10,
      name: formData.name.length >= 2
    };
    setValidation(newValidation);
    return Object.values(newValidation).every(Boolean);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: value
    }));
    setValidation(prev => ({
      ...prev,
      [id]: true
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setFormStatus({ isSubmitting: true, isSubmitted: false, error: null });

    try {
      const response = await fetch(process.env.REACT_APP_FORMSPREE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }
      
      setFormStatus({
        isSubmitting: false,
        isSubmitted: true,
        error: null
      });
      
      setFormData({ email: '', message: '', name: '' });
      
      setTimeout(() => {
        setFormStatus(prev => ({ ...prev, isSubmitted: false }));
      }, 5000);
    } catch (error) {
      setFormStatus({
        isSubmitting: false,
        isSubmitted: false,
        error: 'Failed to send message. Please try again.'
      });
    }
  };

  return (
    <div className="bg-black/40 backdrop-blur-lg rounded-xl p-8 max-w-2xl mx-auto
                    border-2 border-green-500">
      <h2 className="text-3xl font-bold text-green-400 mb-6">Contact Us</h2>
      
      {formStatus.isSubmitted && (
        <div className="mb-6 p-4 bg-green-500/20 border border-green-500 rounded-lg">
          <p className="text-green-400">Thank you for your message! We'll get back to you soon.</p>
        </div>
      )}
      
      {formStatus.error && (
        <div className="mb-6 p-4 bg-red-500/20 border border-red-500 rounded-lg">
          <p className="text-red-400">{formStatus.error}</p>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-gray-300 mb-2">Name</label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleInputChange}
            className={`w-full bg-black/40 border ${!validation.name ? 'border-red-500' : 'border-green-500/50'} rounded-lg px-4 py-2 text-gray-200 focus:outline-none focus:border-green-400`}
            placeholder="Your name"
          />
          {!validation.name && (
            <p className="mt-2 text-red-400 text-sm">Please enter your name</p>
          )}
        </div>

        <div>
          <label htmlFor="email" className="block text-gray-300 mb-2">Email</label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            className={`w-full bg-black/40 border ${!validation.email ? 'border-red-500' : 'border-green-500/50'} rounded-lg px-4 py-2 text-gray-200 focus:outline-none focus:border-green-400`}
            placeholder="your@email.com"
          />
          {!validation.email && (
            <p className="mt-2 text-red-400 text-sm">Please enter a valid email address</p>
          )}
        </div>
        
        <div>
          <label htmlFor="message" className="block text-gray-300 mb-2">Message</label>
          <textarea
            id="message"
            value={formData.message}
            onChange={handleInputChange}
            rows={4}
            className={`w-full bg-black/40 border ${!validation.message ? 'border-red-500' : 'border-green-500/50'} rounded-lg px-4 py-2 text-gray-200 focus:outline-none focus:border-green-400`}
            placeholder="Your message..."
          />
          {!validation.message && (
            <p className="mt-2 text-red-400 text-sm">Message must be at least 10 characters long</p>
          )}
        </div>
        
        <button
          type="submit"
          disabled={formStatus.isSubmitting}
          className={`w-full ${formStatus.isSubmitting ? 'bg-gray-600' : 'bg-green-600 hover:bg-green-700'} text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center`}
        >
          {formStatus.isSubmitting ? (
            <span className="inline-block animate-pulse">Sending...</span>
          ) : (
            'Send Message'
          )}
        </button>
      </form>
    </div>
  );
};

export default FormspreeContact;
