import React from 'react';
import { 
  Search, 
  Settings, 
  Zap, 
  BarChart 
} from 'lucide-react';

const HowItWorks = () => {
  const steps = [
    {
      icon: Search,
      title: "Discovery",
      description: "We analyze your current processes and identify automation opportunities",
      color: "bg-blue-500"
    },
    {
      icon: Settings,
      title: "Custom Solution",
      description: "Our team develops a tailored automation strategy for your needs",
      color: "bg-purple-500"
    },
    {
      icon: Zap,
      title: "Implementation",
      description: "Seamless integration of automation solutions into your workflow",
      color: "bg-orange-500"
    },
    {
      icon: BarChart,
      title: "Optimization",
      description: "Continuous monitoring and improvement of automated processes",
      color: "bg-green-500"
    }
  ];

  return (
    <section id="how-it-works" className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-green-400 text-center mb-12">How It Works</h2>
        
        <div className="relative">
          {/* Connection Line */}
          <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-green-500/20 hidden md:block" />
          
          <div className="space-y-12 relative">
            {steps.map((step, index) => {
              const Icon = step.icon;
              return (
                <div 
                  key={index}
                  className={`flex flex-col md:flex-row ${
                    index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                  } items-center gap-8 md:gap-16`}
                >
                  <div className={`w-full md:w-1/2 ${
                    index % 2 === 0 ? 'md:text-right' : 'md:text-left'
                  }`}>
                    <div className="bg-black/40 backdrop-blur-lg rounded-xl p-6 
                                  border-2 border-green-500 hover:border-green-400
                                  transform hover:scale-105 transition-all duration-300
                                  shadow-[0_0_15px_rgba(34,197,94,0.3)]">
                      <h3 className="text-2xl font-semibold text-gray-200 mb-4">{step.title}</h3>
                      <p className="text-gray-400">{step.description}</p>
                    </div>
                  </div>
                  
                  <div className="relative flex items-center justify-center">
                    <div className={`w-16 h-16 rounded-full ${step.color}/20 flex items-center justify-center
                                   border-2 border-green-500 bg-black/40 backdrop-blur-lg
                                   z-10 transform hover:scale-110 transition-all duration-300`}>
                      <Icon className="w-8 h-8 text-green-400" />
                    </div>
                  </div>
                  
                  <div className="w-full md:w-1/2" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
