import React from 'react';
import {
  BadgeCheck,
  Sparkles,
  ChartNoAxesCombined 
} from 'lucide-react';

const coreValues = [
  {
    title: 'Innovation',
    description:
      'Pioneering new technologies to drive progress and efficiency in our solutions.',
    icon: <Sparkles className="h-6 w-6 text-green-400 mx-auto mb-2" />,
  },
  {
    title: 'Quality',
    description:
      'Ensuring excellence in every product we deliver through rigorous standards.',
    icon: <BadgeCheck className="h-6 w-6 text-green-400 mx-auto mb-2" />,
  },
  {
    title: 'Performance',
    description:
      'Optimizing our applications to deliver seamless and reliable user experiences.',
    icon: <ChartNoAxesCombined className="h-6 w-6 text-green-400 mx-auto mb-2" />,
  },
];

const AboutSection = () => {
  return (
    <section id="about" className="py-16">
      <div className="container mx-auto px-4">
        <div
          className="bg-black/40 backdrop-blur-lg rounded-xl p-8 max-w-3xl mx-auto
                     border-2 border-green-500"
        >
          <h2 className="text-3xl font-bold text-green-400 mb-6">About Us</h2>
          <p className="text-gray-300 mb-4">
            At Ten-X, we are dedicated to driving innovation and efficiency within
            the automotive industry through our cutting-edge automation solutions.
            Our mission is to empower automotive companies of all sizes to optimize
            their operations, enhance productivity, and accelerate growth by
            leveraging intelligent automation technology.
          </p>
          <p className="text-gray-300 mb-4">
            With years of experience in the software development and automotive
            industries, we understand what it takes to build software that not only
            looks great but also performs exceptionally well.
          </p>
          {/* Core Values Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
            {coreValues.map(({ title, description, icon }) => (
              <div
                key={title}
                className="text-center p-4 bg-black/20 rounded-lg border border-green-500/30"
              >
                {icon}
                <h3 className="text-green-400 font-semibold mb-2">{title}</h3>
                <p className="text-gray-400 text-sm">{description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
