import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Quote } from 'lucide-react';

const Testimonials = () => {
  const testimonials = [
    {
      name: "Claudia Orr",
      role: "CTO",
      content: "Ten-X has revolutionized our workflow automation. We've seen a 40% increase in productivity since implementation.",
      image: "/api/placeholder/64/64"
    },
    {
      name: "Michael Chen",
      role: "Operations Director",
      content: "The customization capabilities of Ten-X's automation solutions are unmatched. It's transformed how we handle our processes.",
      image: "/api/placeholder/64/64"
    },
    {
      name: "Random",
      role: "CEO",
      content: "Working with Ten-X has been a game-changer. Their automation expertise and support are exceptional.",
      image: "/api/placeholder/64/64"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleNext = useCallback(() => {
      if (!isAnimating) {
        setIsAnimating(true);
        setCurrentIndex((prev) => (prev + 1) % testimonials.length);
      }
    }, [isAnimating, testimonials.length]);


  const handlePrev = useCallback(() => {
      if (!isAnimating) {
        setIsAnimating(true);
        setCurrentIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
      }
    }, [isAnimating, testimonials.length]);


  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => setIsAnimating(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  useEffect(() => {
      const interval = setInterval(handleNext, 5000);
      return () => clearInterval(interval);
    }, [handleNext]);


  return (
    <section id="testimonials" className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-green-400 text-center mb-12">What Our Clients Say</h2>
        
        <div className="relative max-w-4xl mx-auto">
          <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 
                         flex justify-between z-10 px-4">
            <button 
              onClick={handlePrev}
              className="p-2 rounded-full bg-black/40 border border-green-500 
                       text-green-400 hover:bg-green-500 hover:text-white
                       transition-all duration-300"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button 
              onClick={handleNext}
              className="p-2 rounded-full bg-black/40 border border-green-500 
                       text-green-400 hover:bg-green-500 hover:text-white
                       transition-all duration-300"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>

          <div className="overflow-hidden relative">
            <div 
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {testimonials.map((testimonial, index) => (
                <div 
                  key={index}
                  className="w-full flex-shrink-0 px-4"
                >
                  <div className="bg-black/40 backdrop-blur-lg rounded-xl p-8
                                border-2 border-green-500
                                shadow-[0_0_15px_rgba(34,197,94,0.3)]">
                    <Quote className="w-12 h-12 text-green-400 mb-6" />
                    <p className="text-gray-300 text-lg mb-6">{testimonial.content}</p>
                    <div className="flex items-center">
                      <img 
                        src={testimonial.image} 
                        alt={testimonial.name}
                        className="w-12 h-12 rounded-full border-2 border-green-500"
                      />
                      <div className="ml-4">
                        <h4 className="text-gray-200 font-semibold">{testimonial.name}</h4>
                        <p className="text-green-400">{testimonial.role}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-center mt-6 space-x-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  index === currentIndex 
                    ? 'bg-green-400 w-6' 
                    : 'bg-green-400/40 hover:bg-green-400/60'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
