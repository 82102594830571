import React from 'react';
import { ChevronRight, Bot, Cpu, LineChart, Zap } from 'lucide-react';

const HeroSection = () => {
  return (
    <section id="home" className="py-16 relative overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0 z-0">
        <div 
          className="absolute w-96 h-96 bg-green-500/10 rounded-full blur-3xl -top-12 -right-12"
          style={{ animation: 'pulse 8s infinite' }}
        />
        <div 
          className="absolute w-96 h-96 bg-green-500/5 rounded-full blur-3xl bottom-0 -left-12"
          style={{ animation: 'pulse 8s infinite 1s' }}
        />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Left content */}
          <div className="flex-1 space-y-6">
            <div className="inline-flex items-center px-3 py-1 rounded-full 
                          border border-green-500/30 bg-green-500/10 text-green-400
                          animate-bounce">
              <span className="text-sm">Transforming Business Through Technology</span>
            </div>
            
            <h1 className="text-5xl lg:text-6xl font-bold text-gray-100 leading-tight">
              Intelligent
              <span className="text-green-400 block">Automation</span>
              Solutions
            </h1>

            <p className="text-xl text-gray-300 leading-relaxed">
              Streamline your operations with Ten-X's cutting-edge automation platform. 
              Boost efficiency, reduce costs, and accelerate growth.
            </p>

            <div className="flex flex-col sm:flex-row gap-4 pt-4">
              <button className="px-8 py-4 bg-green-500 hover:bg-green-600 text-white
                               rounded-lg font-semibold transition-all duration-300
                               transform hover:scale-105 flex items-center justify-center
                               shadow-lg shadow-green-500/30">
                Get Started
                <ChevronRight className="ml-2 w-5 h-5" />
              </button>
              <button className="px-8 py-4 border-2 border-green-500 text-green-400
                               rounded-lg font-semibold hover:bg-green-500/10
                               transition-all duration-300">
                Watch Demo
              </button>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 pt-8 border-t border-green-500/20">
              {[
                { label: 'Processes Automated', value: 'xxx' },
                { label: 'Time Saved', value: '2.5M hrs' },
                { label: 'Client Satisfaction', value: '99%' }
              ].map((stat, index) => (
                <div key={index} className="text-center">
                  <div className="text-2xl font-bold text-green-400">{stat.value}</div>
                  <div className="text-sm text-gray-400">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Right content - Animated illustration */}
          <div className="flex-1 relative">
            <div className="relative w-full aspect-square max-w-lg mx-auto">
              {/* Center circle - visible on all screens */}
              <div className="absolute inset-1/4 bg-green-500/10 rounded-full 
                            border-2 border-green-500/30 backdrop-blur-sm
                            animate-pulse">
                <Bot className="absolute inset-1/4 w-1/2 h-1/2 text-green-400" />
              </div>

              {[Cpu, LineChart, Zap].map((Icon, index) => (
                <div
                  key={index}
                  className="absolute w-16 h-16 bg-black/40 rounded-full
                            backdrop-blur-sm border border-green-500
                            hidden lg:flex items-center justify-center"
                  style={{
                    animation: `orbit ${8 + index * 2}s linear infinite`,
                    transformOrigin: 'center',
                    left: '50%',
                    top: '50%',
                    transform: `rotate(${index * 120}deg) translateX(150px) rotate(-${index * 120}deg)`,
                  }}
                >
                  <Icon className="w-8 h-8 text-green-400" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Animation keyframes */}
      <style jsx>{`
        @keyframes orbit {
          from {
            transform: rotate(0deg) translateX(150px) rotate(0deg);
          }
          to {
            transform: rotate(360deg) translateX(150px) rotate(-360deg);
          }
        }
        @keyframes pulse {
          0%, 100% {
            opacity: 0.1;
            transform: scale(1);
          }
          50% {
            opacity: 0.3;
            transform: scale(1.2);
          }
        }
      `}</style>
    </section>
  );
};

export default HeroSection;
