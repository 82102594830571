import React, { useEffect, useState } from 'react';
import AboutSection from '../components/AboutSection.jsx';
import FormspreeContact from '../components/FormspreeSection.jsx';
import FeaturesSection from '../components/FeaturesCardSection.jsx'
import HowItWorks from '../components/HowItWorksSection.jsx';
import Testimonials from '../components/TestimonialsSection.jsx';
import HeroSection from '../components/HeroSection';


const Home = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0.5, y: 0.5 });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight,
      });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      style={{
        background: `radial-gradient(
          circle at ${mousePosition.x * 100}% ${mousePosition.y * 100}%,
          rgb(40, 40, 40),
          rgb(0, 0, 0)
        )`,
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
      }}
    >
      {/* Header Bar */}
      <div className="w-full bg-black/80 backdrop-blur-sm border-b border-green-900/50 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-4">
              <div className="w-8 h-8 bg-green-500/20 rounded-full flex items-center justify-center">
                <div className="w-4 h-4 bg-green-400/40 rounded-full" />
              </div>
              <span className="text-green-50 font-semibold">Ten-X</span>
            </div>
            <nav className="hidden md:flex space-x-8">
              <button onClick={() => scrollToSection('home')} 
                className="text-gray-300 hover:text-green-400 transition-colors duration-200">
                Home
              </button>
              <button onClick={() => scrollToSection('features')} 
                className="text-gray-300 hover:text-green-400 transition-colors duration-200">
                Features
              </button>
              <button onClick={() => scrollToSection('how-it-works')} 
                className="text-gray-300 hover:text-green-400 transition-colors duration-200">
                How It Works
              </button>
              <button onClick={() => scrollToSection('testimonials')} 
                className="text-gray-300 hover:text-green-400 transition-colors duration-200">
                Testimonials
              </button>
              <button onClick={() => scrollToSection('about')} 
                className="text-gray-300 hover:text-green-400 transition-colors duration-200">
                About
              </button>
              <button onClick={() => scrollToSection('contact')} 
                className="text-gray-300 hover:text-green-400 transition-colors duration-200">
                Contact
              </button>
            </nav>
            <button
              className="md:hidden text-green-400"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} 
              aria-label="Toggle navigation menu" 
            >
              <div className="w-6 h-0.5 bg-current mb-1" />
              <div className="w-6 h-0.5 bg-current mb-1" />
              <div className="w-6 h-0.5 bg-current" />
            </button>
          </div>
          {/* Optional: Mobile Navigation Menu */}
          {isMobileMenuOpen && (
            <nav className="md:hidden flex flex-col space-y-4 mt-4">
              <button
                onClick={() => {
                  scrollToSection('home');
                  setIsMobileMenuOpen(false);
                }}
                className="text-gray-300 hover:text-green-400 transition-colors duration-200"
              >
                Home
              </button>
              <button
                onClick={() => {
                  scrollToSection('about');
                  setIsMobileMenuOpen(false);
                }}
                className="text-gray-300 hover:text-green-400 transition-colors duration-200"
              >
                About
              </button>
              <button
                onClick={() => {
                  scrollToSection('contact');
                  setIsMobileMenuOpen(false);
                }}
                className="text-gray-300 hover:text-green-400 transition-colors duration-200"
              >
                Contact
              </button>
            </nav>
          )}
        </div>
      </div>

      <HeroSection />
      <FeaturesSection />
      <HowItWorks />
      <Testimonials />
      <AboutSection />


      {/* Contact Section */}
      <section id="contact" className="py-16">
        <div className="container mx-auto px-4">
          <FormspreeContact />
        </div>
      </section>
    </div>
  );
};

export default Home;

